/* eslint-disable no-unused-vars */
import mock from '../mock'
import {
    collection,
    onSnapshot,
    getDocs,
    query,
    where,
    orderBy,
    limit,
    startAfter
  } from "firebase/firestore"
  import { db } from "../../firebase"

// ** Utils
import { paginateArray } from '../utils'

const data = {
  categories: [
    {
        id: 1,
        name: 'Main Chef',
        description: 'Yotz PVT LTD',
        type: 'editor',
        status: 'Active',
        img: ''
      },
    {
        id: 2,
        name: 'Two Chef',
        description: 'Yotz PVT LTD',
        type: 'editor',
        status: 'Active',
        img: ''
      },
    {
        id: 3,
        name: 'One Chef',
        description: 'Yotz PVT LTD',
        type: 'editor',
        status: 'Active',
        img: ''
      }
  ]
}

// useEffect(() => {

const fetchCat = async () => {
    try {
      // Get reference
      const listingsRef = collection(db, "category")


      // Execute query
      const querySnap = await getDocs(listingsRef)

      const listings = []

      querySnap.forEach((doc) => {
        return data.categories.push({ id: doc.id, ...doc.data() })
      })
console.log(data.categories)

    } catch (error) {
    console.log(error)
    }
  }

  fetchCat()
// }, [])
console.log("hello")
// GET ALL DATA
mock.onGet('/api/categories/list/all-data').reply(200, data.categories)

// POST: Add new user
mock.onPost('/apps/categories/add-user').reply(config => {
  // Get event from post data
  const user = JSON.parse(config.data)
  const highestValue = data.categories.reduce((a, b) => (a.id > b.id ? a : b)).id

  user.id = highestValue + 1

  data.categories.push(user)

  return [201, { user }]
})

// GET Updated DATA 
mock.onGet('/api/categories/list/data').reply(config => {
  const {
    q = '',
    page = 1,
    role = null,
    perPage = 10,
    sort = 'asc',
    status = null,
    currentPlan = null,
    sortColumn = 'fullName'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.categories.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    user =>
      (user.name.toLowerCase().includes(queryLowered) ||
        user.type.toLowerCase().includes(queryLowered) ||
        user.description.toLowerCase().includes(queryLowered)) &&
      user.status === (status || user.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      categories: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET USER
mock.onGet('/api/categories/category').reply(config => {
  const { id } = config
  const user = data.categories.find(i => i.id === id)
  return [200, { user }]
})

// DELETE: Deletes User
mock.onDelete('/apps/categories/delete').reply(config => {
  // Get user id from URL
  let userId = config.id

  // Convert Id to number
  userId = Number(userId)

  const userIndex = data.categories.findIndex(t => t.id === userId)
  data.categories.splice(userIndex, 1)

  return [200]
})
